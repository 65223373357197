import * as React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import frogs from "../assets/frogs.jpg";
import kiss from "../assets/kiss.jpg";
import cake from "../assets/cake.png";
import cats from "../assets/cats.png";
import Sparkles from 'react-sparkle';
import { motion, Variants } from "framer-motion";

const variants: Variants = {
            offscreen: {
              rotate: 10,
            },
            onscreen: {
              rotate: 0,
              transition: {
                staggerChildren: 0.1
              }
            }
          };

const IndexPage = () => {
  return (
    <main
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>happy birthday to a very cool person i kinda really like dot com</title>
        <meta name="description" content="happy birthday to a very cool person i kinda really like dot com" />
      </Helmet>
      <body className="font-serif">
        <div className="grid place-content-center my-12 gap-4 mx-4"
        >
          <motion.div className="grid gap-4" initial="onscreen" whileInView="onscreen" viewport={{
            once: true
          }} variants={variants}>

          <motion.p variants={variants}>angela!</motion.p>
          <motion.p variants={variants}>hi :)</motion.p>
          <motion.p variants={variants}>it is your birthday!!!!</motion.p>
          <motion.img
            src={cake}
            alt="cake"
            variants={variants}
          />
          <motion.p variants={variants}>you probably already knew that.</motion.p>
          <div className="flex sm:flex-row flex-col"><div>but! you didn't know that i...{" "}&nbsp;</div> <div className=" relative">
            <div className="opacity-0 hover:opacity-100">{" "}think you're very cool and <s>kinda</s> really like you.</div>    <div className="hover:hidden translate-y-[-12px]">
              <Sparkles color="teal" overflowPx={8} />
</div>
            </div>
</div>
          <p>...okay, you probably already knew that too.</p>
          </motion.div>
          <hr/>
          <motion.div initial="onscreen" className="grid gap-4" whileInView="onscreen" viewport={{
            once: true
          }} variants={variants}>
          <motion.p variants={variants}>this pic still makes me jealous</motion.p>
          <img
            src={kiss}
            alt="kiss"
          />
          <p>i was less than a meter away?? and u still kissed snow instead of me :(</p>
</motion.div>
          <hr />
          <p>i stumbled upon this frog meme and thought it was cute</p>
          <img
            src={frogs}
            alt="frogs"
          />
          <p>i am moosh because all i want rn is to hug u!</p>
          <hr />
          <p>
            here's a <a href="https://open.spotify.com/playlist/3bLMif80ZesqfLfdhxnw6m?si=733026b46a524a53&pt=d6253c7a51e258dff00dee6da375b832" className='underline text-blue-800'>playlist</a> for u.
          </p>
          <hr />
          i hope u have a wonderful day today. see u soon!!!
          <img src={cats} alt="u showing me around cornell in 3 days" />
          (what cool ai models think we will look like when u show me around cornell in 3 days)
        </div>
      </body>
    </main>
  );
};

export default IndexPage;
